import './src/css/tailwind.css';

export const onServiceWorkerUpdateReady = () => window.location.reload();

// export const onInitialClientRender = () => {
//   console.log('ReactDOM.render has executed');
//   console.log(navigator.language);
//   console.log(localStorage.getItem('gatsby-intl-language'));
//   if (navigator.language !== localStorage.getItem('gatsby-intl-language')) {
//     localStorage.removeItem('gatsby-intl-language');
//     // windows.location.reload();
//   }
// };
